import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Pages
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // Buy
  {
    path: '/properties/for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/properties/land/for-sale',
    name: 'Land For Sale',
    component: () => import('../views/LandForSale.vue'),
  },
  {
    path: '/properties/commercial/for-sale',
    name: 'Commercial Properties For Sale',
    component: () => import('../views/CommercialPropertiesForSale.vue'),
  },
  {
    path: '/properties/business/for-sale',
    name: 'Business Properties For Sale',
    component: () => import('../views/BusinessPropertiesForSale.vue'),
  },
  {
    path: '/open-homes',
    name: 'Open Homes',
    component: () => import('../views/InspectionTimes.vue'),
  },
  // RENT
  {
    path: '/properties/for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/properties/commercial/for-rent',
    name: 'Commercial Properties For Rent',
    component: () => import('../views/CommercialPropertiesForRent.vue'),
  },
  {
    path: '/properties/sold',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
  },
  {
    path: '/blogs',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactUs.vue'),
  },
  // Dynamic Pages
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/profiles/:id/appraisal',
    name: 'Profile Appraisal',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileAppraisal.vue'),
  },
  {
    path: '/profiles/:id/survey',
    name: 'Profile Survey',
    meta: {
      hide: true,
    },
    component: () => import('../views/Survey.vue'),
  },
  // Other
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: {
      hide: true,
    },
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/tv-videos',
    name: 'TV Display Videos',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/TVDisplayBlogs.vue'),
  },
  {
    path: '/tv-rentals',
    name: 'TV Display Rentals',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/TVDisplayRentals.vue'),
  },
  {
    path: '/tv-sales',
    name: 'TV Display Sales',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/TVDisplaySales.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
